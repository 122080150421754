import React, { useState } from 'react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { catalog3d } from "../../state/state"

import './Catalog3d.scss'
import { Button } from '../Button/Button'
import { PopUpForm } from '../PopUpForm/PopUpForm'
import { API_PATH } from '../../Constant/constant'
import { SuccesForm } from '../SuccesForm/SuccesForm'

type TypePropsCatalog3d = {}

export const Catalog3d: React.FC<TypePropsCatalog3d> = ({ }) => {

    const [changeBlock, setChangeBlock] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [valueNameForm, setValueNameForm] = useState(true)

    const onClickChange = () => {
        setChangeBlock(!changeBlock)
    }

    const onClickOpenForm = (value: boolean) => {
        setValueNameForm(value)
        setOpenForm(!openForm)
    }

    const onClickCloseForm = () => {
        setValueNameForm(true)
        setOpenForm(!openForm)
    }

    return (
        <section className='catalog3d'>
            {openForm
                ?
                !changeBlock
                    ?
                    <PopUpForm
                        api={API_PATH}
                        changeBlock={changeBlock}
                        valueNameForm={valueNameForm}
                        onClickCloseForm={onClickCloseForm}
                        onClickChange={onClickChange}
                    />
                    :
                    <SuccesForm callback={onClickCloseForm} />
                :
                ''
            }
            <div className="catalog3dWrapper">
                <div className="catalog3dTitle">
                    <h1>КАТАЛОГ <span>3d ПАНЕЛЕЙ</span></h1>
                </div>
                <div className="fotoPanel3d">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={5}
                        breakpoints={{
                            800: {
                                slidesPerView: 3
                            },
                            640: {
                                slidesPerView: 2
                            },
                            480: {
                                slidesPerView: 1
                            }
                        }}

                        navigation
                    >
                        {catalog3d.map(el => {
                            return (
                                <SwiperSlide key={el.id}><img src={el.itemFoto} alt="" /></SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <div className="name">3D панели</div>
                    <div className="prise">От 1500 ₽ до 2500 ₽</div>
                    <div className="description">
                        <p>Декаротивные рейки любых цветов на выбор любой длины</p>
                        <p>Декаротивные перегородки из дерева  любых цветов любой сложности</p>
                        <p>Окраска досок любым цветом с анисэптиками и маслом</p>
                        <p>Основа фанера</p>
                    </div>
                </div>
                <Button className={'btmCatalog3d'} callBack={() => onClickOpenForm(true)}>ЗАКАЗАТЬ</Button>
            </div>
        </section>
    )
}
