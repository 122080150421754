import { useEffect, useRef, useState } from 'react'

import './HeaderNav.scss'

import { ButtonContacts } from '../../ButtonContacts/ButtonContacts'

import decor from '../../../images/fonIcon.png'
import logo from '../../../images/Layer_1.png'
import logoWhite from '../../../images/logoWhite.jpg'
import { CloseButton } from '../../CloseButton/CloseButton'
import { NavLink } from 'react-router-dom'

type TypePropsHeaderNav = {
  catalog?: boolean
  page?: boolean
}

export const HeaderNav: React.FC<TypePropsHeaderNav> = ({ catalog, page }) => {

  const [size, setSize] = useState({
    width: window.innerWidth
  })

  useEffect(() => {
    const resize = () => {
      setSize({
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [size.width])

  const ref = useRef<HTMLDivElement>(null)

  const [canselBlock, setCanselBlock] = useState(false)

  useEffect(() => {
    if (!canselBlock) {
      if (ref.current) {
        ref.current.style.display = `block`
        ref.current.style.right = `-${size.width}px`
        ref.current.style.transition = `unset`
      }
    } else {
      if (ref.current) {
        ref.current.style.display = `block`
        ref.current.style.right = `0px`
        ref.current.style.transition = `right 0.3s ease`
      }
    }
  }, [canselBlock, size.width])

  const onClickCancel = () => {
    setCanselBlock(!canselBlock)
  }

  const onClickHandlerOpen = () => {
    setCanselBlock(!canselBlock)
  }

  console.log(catalog);


  return (
    <nav className='nav'>

      <div className="navWrapper">
        <div className="navWrapperLogo">
          <img src={logo} alt="logo" />
          <div className='decor'>
            <NavLink to={'/'}><img src={decor} alt="decor" /></NavLink>
          </div>
        </div>
        {catalog
          ?
          <ul className="navWrapperCatalog">
            <li><NavLink to={'/'}>На главную</NavLink></li>
            <li><ButtonContacts blue={true} /></li>
          </ul>
          :
          <ul className="navWrapperNav">
            <li><NavLink to={'/catalog'}>Каталог</NavLink></li>
            <li><a href="#catalog">Столы</a></li>
            <li><a href="#consultation">Консультация</a></li>
            <li><a href="#question">Вопросы</a></li>
            <li><ButtonContacts blue={true} /></li>
          </ul>
        }
        {!catalog &&
          <div className="mobileMenu" onClick={onClickHandlerOpen}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        }
      </div>
      {!catalog
        &&
        <div ref={ref} className='mobileMenuNav'>
          <CloseButton color={true} callBack={onClickCancel} />
          <div className='navMobileWrapper'>
            <ul className="navMobile">
              <li onClick={onClickCancel}><NavLink to={'/catalog'}>Каталог</NavLink></li>
              <li onClick={onClickCancel}><a href="#catalog">Столы</a></li>
              <li onClick={onClickCancel}><a href="#consultation">Консультация</a></li>
              <li onClick={onClickCancel}><a href="#question">Вопросы</a></li>
              <li onClick={onClickCancel}><a href="#question">Контакты</a></li>
              <li><ButtonContacts blue={false} /></li>
              <div className='logoNav'><img src={logoWhite} alt="logoWhite" /></div>
            </ul>
          </div>
        </div>
      }
    </nav>
  )
}

