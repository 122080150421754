import { v1 } from "uuid";

import mini1 from "../images/Mini1.jpg";
import mini2 from "../images/Mini2.jpg";
import blackWalnut1 from "../images/blackWalnut1.jpg";
import blackWalnut2 from "../images/blackWalnut2.jpg";
import blackWalnut3 from "../images/blackWalnut3.jpg";
import blackWalnut4 from "../images/blackWalnut4.jpg";
import dinerTable2 from "../images/9305c59d5d3adc7c2164.jpg";
import magazineTable from "../images/4111.jpg";
import dinerTable1 from "../images/49652532.jpg";
import gnom from "../images/gnom.jpg";
import gnom1 from "../images/gnom1.jpg";
import gnom2 from "../images/gnom2.jpg";
import gnom3 from "../images/gnom3.jpg";
import ctol1 from "../images/stol1.jpg";
import ctol2 from "../images/stol2.jpg";
import ctol3 from "../images/stol3.jpg";
import ctol4 from "../images/stol4.jpg";
import komod2 from "../images/komod2.jpg";
import komod3 from "../images/komod3.jpg";
import komod4 from "../images/komod4.jpg";
import komod5 from "../images/komod5.jpg";
import black_walnut from "../images/black_walnut.jpg";
import black_walnut1 from "../images/black_walnut1.jpg";
import black_walnut2 from "../images/black_walnut2.jpg";
import black_walnut3 from "../images/black_walnut3.jpg";
import tableDining1 from "../images/tableDining1.jpg";
import tableDining2 from "../images/tableDining2.jpg";
import coffeeTable1 from "../images/coffeeTable1.jpg";
import coffeeTable2 from "../images/coffeeTable2.jpg";
import coffeeTableMini1 from "../images/coffeeTableMini1.jpg";
import coffeeTableMini2 from "../images/coffeeTableMini2.jpg";
import coffeeTableMini3 from "../images/coffeeTableMini3.jpg";
import garden1 from "../images/garden1.jpg";
import garden2 from "../images/garden2.jpg";
import garden3 from "../images/garden3.jpg";
import table1 from "../images/table1.jpg";
import table2 from "../images/table2.jpg";
import table3 from "../images/table3.jpg";
import table4 from "../images/table4.jpg";
import table5 from "../images/table5.jpg";
import table6 from "../images/table6.jpg";
import fasad from "../images/fasad.jpg";

import panel3d1 from "../images/3df1.jpg"
import panel3d2 from "../images/3df2.jpg"
import panel3d3 from "../images/3df3.jpg"
import panel3d4 from "../images/3df4.jpg"
import panel3d5 from "../images/3df5.jpg"
import panel3d6 from "../images/3df6.jpg"
import panel3d7 from "../images/3df7.jpg"
import panel3d8 from "../images/3df8.jpg"
import panel3d9 from "../images/3df9.jpg"
import panel3d10 from "../images/3df10.jpg"
import panel3d11 from "../images/3df11.jpg"
import panel3d12 from "../images/3df12.jpg"
import panel3d13 from "../images/3df13.jpg"
import panel3d14 from "../images/3df14.jpg"
import panel3d15 from "../images/3df15.jpg"
import panel3d16 from "../images/3df16.jpg"
import panel3d17 from "../images/3df17.jpg"
import panel3d18 from "../images/3df18.jpg"
import panel3d19 from "../images/3df19.jpg"
import panel3d20 from "../images/3df20.jpg"
import panel3d21 from "../images/3df21.jpg"
import panel3d22 from "../images/3df22.jpg"
import panel3d23 from "../images/3df23.jpg"
import panel3d24 from "../images/3df24.jpg"
import panel3d25 from "../images/3df25.jpg"
import panel3d26 from "../images/3df26.jpg"

export type TypeCatalog3d = {
  id: string;
  itemFoto: string;
}

export const catalog3d: TypeCatalog3d[] = [
  {
    id: v1(),
    itemFoto: panel3d1
  },
  {
    id: v1(),
    itemFoto: panel3d2
  },
  {
    id: v1(),
    itemFoto: panel3d3
  },
  {
    id: v1(),
    itemFoto: panel3d4
  },
  {
    id: v1(),
    itemFoto: panel3d5
  },
  {
    id: v1(),
    itemFoto: panel3d6
  },
  {
    id: v1(),
    itemFoto: panel3d7
  },
  {
    id: v1(),
    itemFoto: panel3d8
  },
  {
    id: v1(),
    itemFoto: panel3d9
  },
  {
    id: v1(),
    itemFoto: panel3d10
  },
  {
    id: v1(),
    itemFoto: panel3d11
  },
  {
    id: v1(),
    itemFoto: panel3d12
  },
  {
    id: v1(),
    itemFoto: panel3d13
  },
  {
    id: v1(),
    itemFoto: panel3d14
  },
  {
    id: v1(),
    itemFoto: panel3d15
  },
  {
    id: v1(),
    itemFoto: panel3d16
  },
  {
    id: v1(),
    itemFoto: panel3d17
  },
  {
    id: v1(),
    itemFoto: panel3d18
  },
  {
    id: v1(),
    itemFoto: panel3d19
  },
  {
    id: v1(),
    itemFoto: panel3d20
  },
  {
    id: v1(),
    itemFoto: panel3d21
  },
  {
    id: v1(),
    itemFoto: panel3d22
  },
  {
    id: v1(),
    itemFoto: panel3d23
  },
  {
    id: v1(),
    itemFoto: panel3d24
  },
  {
    id: v1(),
    itemFoto: panel3d25
  },
  {
    id: v1(),
    itemFoto: panel3d26
  },
]

export type TypeUseTable =
  | "all"
  | "dinner"
  | "garden"
  | "magazine"
  | "workers"
  | "other"
  | "fasad"
  | "komod";

export type TypeCatalog = {
  id: string;
  name: string;
  imgFoto: Array<string>;
  prise: string;
  size: string;
  height: string;
  thickness: string;
  use: TypeUseTable;
};

export const catalog: TypeCatalog[] = [
  {
    id: v1(),
    name: "Обеденный стол",
    imgFoto: [dinerTable2],
    prise: "90000",
    size: "1800х900mm",
    height: "750mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Журнальный стол",
    imgFoto: [magazineTable],
    prise: "27500",
    size: "900х370mm",
    height: "450mm",
    thickness: "45mm",
    use: "magazine",
  },
  {
    id: v1(),
    name: "Садовый",
    imgFoto: [garden1, garden2, garden3],
    prise: "110000",
    size: "1600х800mm",
    height: "750mm",
    thickness: "50mm",
    use: "garden",
  },
  {
    id: v1(),
    name: "Обеденный стол",
    imgFoto: [dinerTable1],
    prise: "104000",
    size: "1800х900mm",
    height: "750mm",
    thickness: "45mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Тёмный орех",
    imgFoto: [black_walnut, black_walnut1, black_walnut2, black_walnut3],
    prise: "26000",
    size: "700х400mm",
    height: "500mm",
    thickness: "50mm",
    use: "workers",
  },
  {
    id: v1(),
    name: "Гном",
    imgFoto: [gnom1, gnom, gnom2, gnom3],
    prise: "25800",
    size: "700х400mm",
    height: "500mm",
    thickness: "50mm",
    use: "magazine",
  },
  {
    id: v1(),
    name: "Фурия",
    imgFoto: [ctol1, ctol2, ctol3, ctol4],
    prise: "47000",
    size: "1250х800mm",
    height: "750mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Белая река",
    imgFoto: [tableDining1, tableDining2],
    prise: "130000",
    size: "2700х900mm",
    height: "750mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Мини",
    imgFoto: [mini1, mini2],
    prise: "20500",
    size: "1000х550mm",
    height: "500mm",
    thickness: "50mm",
    use: "magazine",
  },
  {
    id: v1(),
    name: "Журнальный",
    imgFoto: [coffeeTable1, coffeeTable2],
    prise: "45000",
    size: "1200х800mm",
    height: "750mm",
    thickness: "50mm",
    use: "magazine",
  },
  {
    id: v1(),
    name: "Деловой",
    imgFoto: [coffeeTableMini1, coffeeTableMini2, coffeeTableMini3],
    prise: "25000",
    size: "600х600mm",
    height: "400mm",
    thickness: "50mm",
    use: "magazine",
  },
  {
    id: v1(),
    name: "Black nut",
    imgFoto: [blackWalnut1, blackWalnut2, blackWalnut3, blackWalnut4],
    prise: "49000",
    size: "1250х800mm",
    height: "750mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Комод",
    imgFoto: [komod2, komod4, komod5, komod3],
    prise: "87500",
    size: "1500х450mm",
    height: "550mm",
    thickness: "50mm",
    use: "komod",
  },
  {
    id: v1(),
    name: "Кофейный",
    imgFoto: [table1],
    prise: "45000",
    size: "1000х1000mm",
    height: "450mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Уют",
    imgFoto: [table2],
    prise: "84000",
    size: "1000х1000mm",
    height: "550mm",
    thickness: "50mm",
    use: "magazine",
  },
  {
    id: v1(),
    name: "Life",
    imgFoto: [table3],
    prise: "190000",
    size: "2000х880mm",
    height: "550mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Лагуна",
    imgFoto: [table4],
    prise: "47800",
    size: "900х900mm",
    height: "500mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Коралловый рифф",
    imgFoto: [table5],
    prise: "68000",
    size: "1000х1000mm",
    height: "550mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Семейный",
    imgFoto: [table6],
    prise: "215000",
    size: "2000х880mm",
    height: "550mm",
    thickness: "50mm",
    use: "dinner",
  },
  {
    id: v1(),
    name: "Фасады",
    imgFoto: [fasad],
    prise: "от 40000",
    size: "",
    height: "",
    thickness: "",
    use: "fasad",
  },
];

export type TypeAccordionData = {
  id: string;
  title: string;
  content: string;
};

export const accordionData: TypeAccordionData[] = [
  {
    id: v1(),
    title: "Насколько бережной должна быть эксплуатация изделий?",
    content: `Это зависит от модели стола. Есть практичные изделия с минимальным количеством смолы или с заливкой мало эксплуатируемых областей поверхности. В таком случае, глянцевая поверхность заливки используется редко, сохраняя свой блеск надолго. К каждому изделию мы прилагаем инструкцию по эксплуатации, следование которой поможет значительно сократить количество реставраций изделия в течении всего срока службы`,
  },
  {
    id: v1(),
    title: "Ваши изделия экологичны?",
    content: `Существует миф о том, что эпоксидная смола вредна для здоровья. Это почти неправда. Вредна она только в процессе заливки. Полимеризация (застывание) смолы сопровождается испарением вредных веществ (фенолы, формальдегиды). Однако, при эксплуатации эпоксидная смола безвредна для здоровья`,
  },
  {
    id: v1(),
    title: "Что делать, когда фактура дерева потускнеет, а смола поцарапается?",
    content: `Стол из массива и смолы может служить десятки лет. При этом, его легко поддерживать в новом состоянии. Процесс реставрации осуществляется на штатном месте стола и включает в себя три этапа: шлифовка мелким зерном, нанесение масла, полировка смолы. Мы предлагаем данную услугу только нашим покупателям. Реставрируем быстро (1 кв.м/час), без пыли и мусора`,
  },
  {
    id: v1(),
    title: "Как сделать заказ?",
    content: `В этом Вам помогут наши дизайнеры. Заказ стола - это творческий процесс, который начинается с выбора модели стола. Далее, дизайнеры подбирают массив. Ассортимент дерева огромен, так как на нашем складе в наличии 150 кубических метров слэбов из разных частей света. Далее, мы визуализируем будущий стол (выкладываем слэбы по тз; обрамляем по форме; делаем дополнительные фото, видео; визуализируем заливку в графическом редакторе). Завершающий этап - составление договора, в котором мы фиксируем все дизайнерские задумки`,
  },
  {
    id: v1(),
    title: "У вас есть доставка?",
    content: `Мы бесплатно доставляем свои изделия по Екатеринбургу. Так же, отправляем в регионы и страны СНГ через транспортные компании (ПЭК, Деловые линии и т.д.) сборным грузом в твердой упаковке. Доставка до терминала бесплатна, далее по тарифом транспортных компаний`,
  },
  {
    id: v1(),
    title: "Какие сроки изготовления?",
    content: `Стандартный срок изготовления - 25 рабочих дней. При необходимости сможем быстрее. Однако, это обсуждается индивидуально`,
  },
  {
    id: v1(),
    title: "Что влияет на стоимость стола?",
    content: `Самый главный критерий ценообразования - размер стола и объем смолы. Так же, на цену влияет сложность дизайна (фигурные края, художественная заливка и т.д)`,
  },
  {
    id: v1(),
    title: "Есть ли гарантия?",
    content: `В своей работе мы используем материалы премиального качества и проверенные технологии изготовления. Благодаря этому, мы уверены в качестве своих изделий и предлагаем гарантию 2 года по договору`,
  },
];

type TypeMobileMenu = {
  id: string;
  icon: string;
  itemMenu: string;
};

export const mobilemenu: TypeMobileMenu[] = [
  {
    id: v1(),
    icon: "M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z",
    itemMenu: "Главная",
  },
  {
    id: v1(),
    icon: "M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z",
    itemMenu: "Обучения",
  },
  {
    id: v1(),
    icon: "M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z",
    itemMenu: "Записаться",
  },
  {
    id: v1(),
    icon: "M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z",
    itemMenu: "Тренеры",
  },
  {
    id: v1(),
    icon: "M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z",
    itemMenu: "Контакты",
  },
];
