import { MouseEvent, useState } from 'react'
import { HeaderMain } from './HeaderMain/HeaderMain'
import { HeaderNav } from './HeaderNav/HeaderNav'

import './Header.scss'

export const Header = () => {
  return (
    <header>
      <HeaderNav />
      <HeaderMain />
    </header>
  )
}
