import ReactDOM from 'react-dom/client';
import './styles/reset.scss';
import './styles/root.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
)
  .render(
    <BrowserRouter>
      <App />
    </BrowserRouter>

  );

reportWebVitals();
