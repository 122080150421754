import React from 'react';
import './App.scss';
import { Header } from './Component/Header/Header';
import { Catalog } from './Component/Catalog/Catalog';
import { Order } from './Component/Order/Order';
import { Answers } from './Component/Answers/Answers';
import { Footer } from './Component/Footer/Footer';
import { SectionForm } from './Component/SectionForm/SectionForm';
import { Route, Routes } from 'react-router-dom';
import { HeaderNav } from './Component/Header/HeaderNav/HeaderNav';
import { HeaderMain } from './Component/Header/HeaderMain/HeaderMain';
import { Catalog3d } from './Component/Catalog3d/Catalog3d';

function App() {
  return (
    <div className='app'>
      <Routes>
        <Route path='/' element={
          <>
            <Header />
            <Catalog page={true} />
            <Catalog3d />
            <SectionForm />
            <Order />
            <Answers />
          </>
        } />
        <Route path='/catalog' element={
          <>
            <HeaderNav catalog={true} page={true} />
            <Catalog />
          </>
        } />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
